/*
    In the real world a lot of this values should come from environment vriables
*/

const config = {
  BFF_BASE_URL: "https://meli-bff.lucassanchez.dev/",
  DEFAULT_SITE_ID: "MLA",
  RESULTS_QUANTITY: 4,
}

export default config
